import React, { useRef, ChangeEvent, useContext, useState, useEffect } from "react" 

export const FormatSelector = (props: any) => {  
    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => { 
          if (props.showFormat && myRef.current && !myRef.current.contains(e.target)) { 
            props.setShowFormat(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside) 
        return () => { 
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [props.showFormat])

    return(
        <div ref={myRef} style={{
            color: "white"
        }}> 
            Формат рекламы
            <div  className="type-sellect">
                <div className="type-sellect__text" onClick={()=>{props.setShowFormat(!props.showFormat)}}>
                    {!props.form.format1 && !props.form.format2 && !props.form.format3 && !props.form.format4 && !props.form.format5 ? "Выбрать" : (
                        <>
                            {props.form.format1 ? "Рекламный пост, " : "" }
                            {props.form.format2 ? "Выкуп на маркетплейсе, " : "" }
                            {props.form.format3 ? "Отзыв на маркетплейсе, " : "" }
                            {props.form.format5 ? "Видеоотзыв на маркетплейсе, " : "" }
                            {props.form.format4 ? "Раздача товаров подписчикам " : "" }
                        </>
                    )}                                                    
                </div>
                {
                    props.showFormat ? (
                        <div className="type-sellect__options">
                            <label className="type-sellect__item">
                                <input type="checkbox" name="format1" checked={props.form.format1 ? true:false} onChange={(e)=>{
                                    props.setForm({ ...props.form, [e.target.name]: e.target.checked })
                                }} />
                                Рекламный пост
                            </label>
                            <label className="type-sellect__item">
                                <input type="checkbox" name="format2" checked={props.form.format2 ? true:false} onChange={(e)=>{
                                    props.setForm({ ...props.form, [e.target.name]: e.target.checked })
                                }} />
                                Выкуп на маркетплейсе
                            </label>
                            <label className="type-sellect__item">
                                <input type="checkbox" name="format3" checked={props.form.format3 ? true:false} onChange={(e)=>{
                                    props.setForm({ ...props.form, [e.target.name]: e.target.checked })
                                }} />
                                Отзыв на маркетплейсе
                            </label>
                            <label className="type-sellect__item">
                                <input type="checkbox" name="format5" checked={props.form.format5 ? true:false} onChange={(e)=>{
                                    props.setForm({ ...props.form, [e.target.name]: e.target.checked })
                                }} />
                                Видеоотзыв на маркетплейсе
                            </label>


                            

                            <label className="type-sellect__item">
                                <input type="checkbox" name="format4" checked={props.form.format4 ? true:false} onChange={(e)=>{
                                    props.setForm({ ...props.form, [e.target.name]: e.target.checked })
                                }} />
                                Раздача товаров подписчикам
                            </label>
                        </div>
                    ):''
                }
            </div>
        </div>
    )
}