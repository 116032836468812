import React, { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext" 
import { SellerSettingPage } from "./SellerSettingPage/SellerSettingPage"
import { BlogerSettingPage } from "./BlogerSettingPage/BlogerSettingPage"

export const SettingPage = () => {  
    const auth = useContext(GlobalContext) 

    return( 
        <>
            {
                auth.user.userType == "bloger" ? (
                    <BlogerSettingPage />
                ):(
                    <SellerSettingPage />
                )
            } 

        </>
    )
}
