import axios from 'axios'
import { error } from 'console'

export const host = "https://api.blogerito.ru/"
// export const host = "http://10.55.1.60:8000/"

export interface QuestionType {
    title: string
    desc: string
    opener: boolean
}

export interface SellerDopInfo {
    pk: string
    bDate: string
    phone: string
    minidesc: string
    desc: string
    doc: string
    name: string
    goodreview: number
    badreview: number
    image: string
    docConf: boolean
    phoneConf: boolean
}

export interface ReviewsInfo {
    pk: number,
    integration: {
        pk: number
        title: string
        seller: {
            name: string,
            minidesc: string,
            image: string
        }
    },
    date: string,
    estimation: string,
    text: string,
    bloger: number
}

export interface UserDopInfo {
    pk: number,
    name: string
    bDate: string
    city: string
    phone: string
    minidesc: string
    category: string
    youtube: string
    vk: string
    instagram: string
    rutube: string
    telegram: string
    desc: string
    doc: string
    subscribers: string
    men: string
    woman: string
    goodreview: string
    badreview: string
    integration: string
    docConf: boolean
    infoConf: boolean
    CatalogConf: boolean
    phoneConf: boolean
    image: string
    postType1: boolean
    postType2: boolean
    postType3: boolean
    postType4: boolean
    postType5: boolean
    integrationCost: string
    dateActivity: string
}

export interface ChatListInfo {
    pk: number,
    applicate: {
        pk: number
        status: string
        bloger: {
            pk: number
            name: string
            image: string
        }
    }
    integration: {
        title: string
        productImage: string
        seller: {
            pk: number
            name: string
            image: string
        }
    },
    date: string
    status: string
    sellerOpen: boolean
    blogerOpen: boolean
}

export interface ChatMsgInfo {
    minidesc: string
    image: string
    date: string
    status: string
}

export interface PayInfo{
    title: string
    minidesc: string
    date: string
    count: string
}
export interface JobsApplications {
    "id": number,
    "statusCode": string,
    status: string,
    "bloger": number,
    "seller": number,
    "date": string,
}
export interface Applications{
    "pk": number,
    "bloger": {
        "pk": number,
        "name": string,
        "image": string,
        "integration": string,
        "subscribers": string,
    }
}

export class Question {
    list: QuestionType[]

    constructor(){
        this.list = []
    }

    async Load(){
        let data = true
        await axios.get(`${host}questions/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.list = []
            this.list.shift()
            res.data.forEach((item: QuestionType)=>{
                item.opener = false
                this.list.push({...item})
            })
        })
        .catch(function (error) {
            data = false
        })
        return data
    }
}

export class Integrations {
    pk: number           = 0
    date: string         = ""
    title: string        = ""
    productTitle: string = ""
    productImage: string = ""
    productLink: string  = ""
    miniDesc: string     = ""
    desc: string         = ""
    status: string       = ""
    price: number        = 1
    format1: boolean     = false
    format2: boolean     = false
    format3: boolean     = false
    format4: boolean     = false
    format5: boolean     = false
    maxIntegrations: number = 0
    applicationCount: number = 0
    applications_list: any[] = []
    seller = {
        name: "",
        miniDesc: "",
        image: ""
    }
    statusApplication = ""

    applications: Applications[] | JobsApplications = [] || {  id: 1,
        statusCode: '',
        status: '',
        bloger: 1,
        seller: 1,
        date:''
    }

    constructor(item = {
        pk: 0,
        title: "",
        productTitle: "",
        productImage: "",
        productLink: "",
        miniDesc: "",
        status: "",
        date: "",
        price: 1,
        format1: false,
        format2: false,
        format3: false,
        format4: false,
        format5: false,
        applicationCount: 0,
        maxIntegrations: 0,
        seller: {
            name: "",
            miniDesc: "",
            image: ""
        },
        applications_list: [{}],
        applications:[] || {  id: 1,
            statusCode: '',
            status: '',
            bloger: 1,
            seller: 1,
            date:''
        }
    }){
        if(item.title != ""){
            this.pk = item.pk
            this.title = item.title
            this.productTitle = item.productTitle
            this.productImage = item.productImage
            this.productLink = item.productLink
            this.miniDesc = item.miniDesc
            this.seller = item.seller
            this.status = item.status

            this.date = item.date
            this.price = item.price

            this.format1 = item.format1
            this.format2 = item.format2
            this.format3 = item.format3
            this.format4 = item.format4
            this.format5 = item.format5
            this.applicationCount = item.applicationCount
            this.maxIntegrations = item.maxIntegrations
            this.applications_list = item.applications_list
            this.applications = item.applications
        }
    }


    async SwitchStaus(token: string, status: string, pk = 0){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.post(`${host}bloger-application/`, {}, {
            params: {
                "id": Hthis.pk,
                "status": status,
                "pk": pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res=>{
            data = res.data
            console.log(res.data)
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async ChackStatus(token: string){
        let data = {
            "error": false,
            "userType": "",
            "msg": "",
            "status": "",
            "applications": "",
        }

        const Hthis = this
        await axios.get(`${host}bloger-application/?id=${Hthis.pk}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.statusApplication = res.data.status
            data = res.data
            if(res.data.status == "seller owner"){
                Hthis.applications = res.data.applications
            }
        }).catch(error=>{
            data = {
                "error": true,
                "userType": "",
                "msg": "error",
                "status": "",
                "applications": "",
            }
        })

        return data
    }

    async LoadStat(token: string, id: string){
        let data = {
            "error": false,
            "msg": {
                itemName: "",
                productName: "",
                miniDesc: "",
                desc: "",
                link: "",
                file: "",
                maxIntegrations: "",
                tz: "",
                price: 1,
                format1: false,
                format2: false,
                format3: false,
                format4: false,
                format5: false,
            }
        }
        const Hthis = this

        await axios.get(`${host}integration-setting/`,{
            params: {
                'id': id
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": {
                    itemName: res.data.msg.itemName,
                    productName: res.data.msg.productName,
                    miniDesc: res.data.msg.miniDesc,
                    desc: res.data.msg.desc,
                    link: res.data.msg.link,
                    file: res.data.msg.file,
                    tz: res.data.msg.tz,
                    price: res.data.msg.price,
                    maxIntegrations: res.data.msg.maxIntegrations,
                    format1: res.data.msg.format1,
                    format2: res.data.msg.format2,
                    format3: res.data.msg.format3,
                    format4: res.data.msg.format4,
                    format5: res.data.msg.format5
                }
            }
        }).catch(error => {
            console.log(error)
        })

        return data
    }

    async Load(token: string, id: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}integrations/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
            Hthis.pk = res.data.pk
            Hthis.title = res.data.title
            Hthis.status = res.data.status
            Hthis.productTitle = res.data.productTitle
            Hthis.productImage = res.data.productImage
            Hthis.productLink = res.data.productLink
            Hthis.miniDesc = res.data.miniDesc
            Hthis.seller = res.data.seller
            Hthis.desc = res.data.desc
            Hthis.date = res.data.date

            Hthis.price = res.data.price

            Hthis.format1 = res.data.format1
            Hthis.format2 = res.data.format2
            Hthis.format3 = res.data.format3
            Hthis.format4 = res.data.format4
            Hthis.format5 = res.data.format5
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data
    }
}

export class Application {
    pk: number = 0
    integration: Integrations = new Integrations()
    status: string = ""
    date: string = ""

    constructor(item: {pk: number, integration: Integrations, status: string, date: string}){
        this.pk = item.pk
        this.integration = item.integration
        this.status = item.status
        this.date = item.date
    }

    async Respond(token: string, status: boolean){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.post(host+"respond-applications/", {
            project: Hthis.pk,
            status: status
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then((res) => {
            data = {
                "error": true,
                "msg": res.data.msg
            }
        })
        .catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }
}

export class Chat {
    chatList: ChatListInfo[] = []
    chatMsg: ChatMsgInfo[] = []
    chatNames = [""]
    targetChat = 0
    targetStatus = ""
    constructor(){
        this.chatList.slice()
        this.chatMsg.slice()
    }

    async GetApplicationChatID(token: string, applicationID: number){
        const Hthis = this
        await axios.get(`${host}my-task-chat/`, {
            params: {
                id: applicationID
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.targetChat = res.data.chatID
            Hthis.targetStatus = res.data.status
        }).catch(function(error){
            console.log("error")
        })
        return true
    }

    async SendMsg(token: string, msg: string, msgFile: any){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        let msgArray = msg.split(" ")
        let msgString = ""
        msgArray.forEach(item => {
            if (item.indexOf("http") >= 0)
                item = `<a href="${item}" target="_blank">${item}</a>`
            msgString += `${item} `
        })

        const form = new FormData()
        form.append("image", msgFile)
        form.append("minidesc", msg)


        await axios.post(`${host}chat/?id=${this.targetChat}`,form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.chatMsg = []
            console.log(res.data)
            res.data.content.forEach((item: ChatMsgInfo, index: number) => {
                Hthis.chatMsg.push({...item})
            })
        }).catch(error=>{
            console.log(error.data)
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data
    }

    async UpdateChatItem(token: string, pk: number, status: string){
        this.targetChat = pk
        this.targetStatus = status

        const Hthis = this
        await axios.get(`${host}chat/${pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.chatMsg = []
            console.log(res.data)
            res.data.content.forEach((item: ChatMsgInfo, index: number) => {
                const hDesc = item.minidesc
                item.minidesc = ""
                hDesc.split(' ').map((item2, index) => {
                    if(item2.includes('http'))
                        item.minidesc += `<a href="${item2}" target="_blank">${item2}</a> `
                    else
                        item.minidesc += `${item2} `
                })
                Hthis.chatMsg.push({...item})
            })
        }).catch(error=>{
        })

        return Hthis.chatMsg
    }

    async UpdateList(token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        this.chatNames.shift()
        await axios.get(`${host}chat/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.chatNames = [""]
            Hthis.chatList = []
            res.data.content.forEach((item: ChatListInfo, index: number) => {
                if(!Hthis.chatNames.includes(item.integration.title))
                    Hthis.chatNames.push(item.integration.title)

                Hthis.chatList.push({...item})
            })
            Hthis.chatNames.sort()

            if(Hthis.chatList.length){
                Hthis.targetChat = Hthis.chatList[0].pk
                Hthis.targetStatus = Hthis.chatList[0].applicate.status
            }
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async CommentOrder(token: string, comment: string, stars: number){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.post(`${host}comment-order/`, {
            comment: comment,
            stars: stars,
            chat: Hthis.targetChat,
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
            data = {
                "error": false,
                "msg": "Выполнение продолжается"
            }
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async CancelCompletion(token: string, msg: string){
        let data = {
            "error": false,
            "msg": ""
        }

        const Hthis = this
        await axios.post(`${host}chat-cancel-completion/`, {
        },{
            params: {
                msg: msg,
                chat: Hthis.targetChat
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": "Выполнение продолжается"
            }
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async CompleteOrder(token: string, reason: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        const form = new FormData();
        form.append("reason", reason)

        await axios.post(`${host}chat-complete/`, form, {
            params: {
                chat: Hthis.targetChat
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": "Запрос выполнен"
            }
            Hthis.targetChat = 0
            Hthis.targetStatus = ""
            Hthis.chatList = []
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async CancelOrder(token: string, reason: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        const form = new FormData();
        form.append("reason", reason)

        await axios.post(`${host}chat-cancel/`, form ,{
            params: {
                chat: Hthis.targetChat
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": "Запрос выполнен"
            }
            Hthis.targetChat = 0
            Hthis.targetStatus = ""
            Hthis.chatList = []
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async ChatDelete(token: string){
        const Hthis = this
        await axios.post(`${host}chat-delete/`, {}, {
            params: {
                chat: Hthis.targetChat
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(function(response){}).catch(function(error){})
        return {
            "error": false,
            "msg": ""
        }
    }
}

export class IntegrationsList {
    countPage: number = 1
    targetPage: number = 1
    applications: Integrations[] = []

    constructor(){

    }

    async PayConf(pk: number, token: string, integration: number){
        const Hthis = this
        await axios.post(`${host}my-tasks/`, {
            "id": pk,
            "status": "3"
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(res => {

            Hthis.applications[integration].applications_list.forEach((item, index) => {
                if(item.pk == pk){
                    Hthis.applications[integration].applications_list[index].status = "payment_w8"
                    console.log(Hthis.applications[integration].applications_list[index].status)
                }
            })
        }).catch(error=>{

        })
    }

    async Respond(token: string, status: boolean, pk: number, blogerId: number, itemIndex: number){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.post(`${host}bloger-application/`, {}, {
            params: {
                "id": pk,
                "status": `${status}`,
                "pk": blogerId //bloger
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res=>{
            data = res.data
            Hthis.applications[itemIndex].applications_list = res.data.applications_list
            console.log(res.data.applications_list)
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async Load(token: string, type: string, search: string, all = true, actual = false, is_bloger = false){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        let link = ""
        if(actual && type != "story")
            link = "my-integrations/"
        else
            link = "integrations/"

        await axios.get(`${host}${link}`, {
            params: {
                page: Hthis.targetPage,
                search,
                 type,
                all_user: `${all}`,
                is_bloger
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.applications =  []
            res.data.content.forEach((item: Integrations, index: number) => {
                Hthis.applications.push(new Integrations({...item}))
            })
            console.log(res.data.content)
            Hthis.countPage = Math.ceil(res.data.pageCount / 20)
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data
    }

    async Copy(token: string, index: number){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        await axios.post(`${host}integration-copy/`, {}, {
            params: {
                'id': index,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data
    }

    async Close(token: string, index: number){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        await axios.post(`${host}integration-close/`, {}, {
            params: {
                'id': index,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data

    }

    async Recover(token: string, index: number){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.post(`${host}resumeintegration/`, {}, {
            params: {
                'id': index,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }
}

export class ApplicationList {
    countPage: number = 1
    targetPage: number = 1
    applications: Application[] = []

    constructor(){
    }

    async Load(token: string, type: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}applications/?page=${Hthis.targetPage}&type=${type}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.applications = []

            res.data.content.forEach((item: Application, index: number) => {
                Hthis.applications.push(new Application({...item}))
            })

            Hthis.countPage = Math.ceil(res.data.pageCount / 20)
        })
        .catch(function (error) {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class Bloger{
    dopInfo: UserDopInfo
    userToken: string

    notificationsMail = ""
    sendNotificationsType = ""
    secretKey = ""
    chatID = ""

    constructor (token: string){
        this.userToken = token


        this.dopInfo = {
            pk: 0,
            integrationCost: "",
            name: "",
            image: "",
            bDate: "",
            city: "",
            phone: "",
            minidesc: "",
            category: "",
            youtube: "",
            vk: "",
            instagram: "",
            rutube: "",
            telegram: "",
            desc: "",
            doc: "",
            subscribers: "",
            men: "",
            woman: "",
            goodreview: "",
            badreview: "",
            integration: "",
            docConf: false,
            infoConf: false,
            CatalogConf: false,
            phoneConf: false,
            postType1: false,
            postType2: false,
            postType3: false,
            postType4: false,
            postType5: false,
            dateActivity: ""
        }
    }

    async SaveNotification(formData: {
        notificationsType: string,
        notificationsEmail: string
    }){
        let data = {
            "error": false,
            "msg": "2"
        }
        const token = this.userToken
        const Hthis = this

        await axios.post(host+"notification-update/",{
            "notificationsType": formData.notificationsType,
            "notificationsEmail": formData.notificationsEmail == null ? "" : formData.notificationsEmail
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => {
            console.log(res.data)
            data = {
                "error": true,
                "msg": "Данные обновлены"
            }
        })
        .catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async SaveData(formData: {
        name: string,
        bDate: string,
        city: string,
        minidesc: string,
        category: string,
        phone: string,
        youtube: string,
        vk: string,
        instagram: string,
        telegram: string,
        rutube: string,
        doc: any,
        desc: string,
        postType1: boolean,
        postType2: boolean,
        postType3: boolean,
        postType4: boolean,
        postType5: boolean
    }){
        let data = {
            "error": false,
            "msg": "2"
        }
        const token = this.userToken
        const Hthis = this

        if(formData.youtube == "" && formData.vk == "" && formData.instagram == "" && formData.telegram == "" && formData.rutube == ""){
            return {
                "error": true,
                "msg": "Нужно указать ссылку на соц.сеть"
            }
        }

        const form = new FormData();
        form.append("name", formData.name)
        form.append("bDate", formData.bDate)
        form.append("city", formData.city)
        form.append("minidesc", formData.minidesc)
        form.append("category", formData.category)
        form.append("phone", formData.phone)
        form.append("youtube", formData.youtube)
        form.append("vk", formData.vk)
        form.append("instagram", formData.instagram)
        form.append("telegram", formData.telegram)
        form.append("rutube", formData.rutube)
        form.append("doc", formData.doc)
        form.append("desc", formData.desc)
        form.append("postType1", `${formData.postType1}`)
        form.append("postType2", `${formData.postType2}`)
        form.append("postType3", `${formData.postType3}`)
        form.append("postType4", `${formData.postType4}`)
        form.append("postType5", `${formData.postType5}`)

        await axios.put(host+"bloger/0/",form,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => {
            console.log(res.data)
            data = {
                "error": true,
                "msg": "Данные обновлены"
            }
        })
        .catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async LoadData(user: User){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.get(`${host}bloger/0/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.dopInfo = res.data
            Hthis.notificationsMail = res.data.notificationsMail
            Hthis.sendNotificationsType = res.data.sendNotificationsType
            Hthis.secretKey = res.data.user.first_name
            Hthis.chatID = res.data.user.last_name

            user.userName = res.data.name
            localStorage.setItem("userStorage", JSON.stringify({
                pk: res.data.pk,
                isAuthenticated: true,
                userToken: Hthis.userToken,
                userName: user.userName,
                image: user.image,
                userType: user.userType,
            }))
        })
        .catch(function (error) {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class Seller{
    dopInfo: SellerDopInfo
    userToken: string

    subscribeDate: string
    integrationsCount: string
    pk = 0
    notificationsMail = ""
    sendNotificationsType = ""
    secretKey = ""
    chatID = ""

    constructor (token: string){
        this.userToken = token

        this.subscribeDate = ""
        this.integrationsCount = ""

        this.dopInfo = {
            name: "",
            pk: "",
            bDate: "",
            phone: "",
            minidesc: "",
            desc: "",
            doc: "",
            goodreview: 0,
            badreview: 0,
            image: "",
            docConf: false,
            phoneConf: false
        }

    }

    async NewPay(count: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.post(`${host}score/`, {},{
            params: {
                "count": count
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data.msg)
            data = {
                "error": false,
                "msg": res.data.msg.url
            }
        }).catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async LoadScore(){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.get(`${host}score/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.subscribeDate = res.data.subscribeDate
            Hthis.integrationsCount = res.data.integrationsCount
            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async SaveData(formData: {
        name: string,
        bDate: string,
        phone: string,
        minidesc: string,
        desc: string,
        doc: any
    }){
        let data = {
            "error": false,
            "msg": "2"
        }
        const token = this.userToken
        const Hthis = this

        const form = new FormData();
        form.append("name", formData.name)
        form.append("bDate", formData.bDate)
        form.append("desc", formData.desc)

        form.append("doc", formData.doc)
        form.append("minidesc", formData.minidesc)
        form.append("phone", formData.phone)

        await axios.put(host+"seller/0/",form,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => {
            data = {
                "error": true,
                "msg": "Данные обновлены"
            }
        })
        .catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async SaveNotification(formData: {
        notificationsType: string,
        notificationsEmail: string
    }){
        let data = {
            "error": false,
            "msg": "2"
        }
        const token = this.userToken
        const Hthis = this

        if(formData.notificationsEmail == null)
            formData.notificationsEmail = ""

        await axios.post(host+"notification-update/",{
            "notificationsType": formData.notificationsType,
            "notificationsEmail": formData.notificationsEmail == null ? "" : formData.notificationsEmail
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => {
            console.log(res.data)
            data = {
                "error": true,
                "msg": "Данные обновлены"
            }
        })
        .catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "Error"
            }
        })
        return data
    }

    async LoadData(user: User){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.get(`${host}seller/0/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.dopInfo = res.data
            console.log(res.data)

            Hthis.notificationsMail = res.data.notificationsMail
            Hthis.sendNotificationsType = res.data.sendNotificationsType
            Hthis.secretKey = res.data.user.first_name
            Hthis.chatID = res.data.user.last_name

            user.userName = res.data.name
            localStorage.setItem("userStorage", JSON.stringify({
                pk: res.data.pk,
                isAuthenticated: true,
                userToken: Hthis.userToken,
                userName: user.userName,
                image: user.image,
                userType: user.userType,
            }))
        }).catch(error => {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class User {
    pk: number = 0
    isAuthenticated: boolean = false
    userToken: string = ""
    userName: string = ""
    image: string = ""
    userType: string = ""
    applicationsActive: Application[]
    applicationsStory: Application[]
    notifications: [{
        title: string
        date: string
        action: string
    }]
    chatCount = 0
    typeObject?: Seller | Bloger

    constructor () {
        this.applicationsActive = []
        this.applicationsStory = []
        this.notifications = [{
            title: "",
            date: "",
            action: ""
        }]
        this.notifications.shift()

        if(localStorage.getItem("userStorage")){
            const userStorage = JSON.parse(localStorage.getItem("userStorage") || "")

            this.pk = userStorage.pk
            this.isAuthenticated = true
            this.userToken = userStorage.userToken
            this.userName = userStorage.userName
            this.image = userStorage.image == "" ? "/media/1e0911d50f7e49b89e052b6d16bdf25d.png" : userStorage.image
            this.userType = userStorage.userType

            this.typeObject = userStorage.userType == "bloger" ? new Bloger(userStorage.userToken) : new Seller(userStorage.userToken)
        }

    }

    logout(){
        localStorage.removeItem("userStorage")
        this.isAuthenticated = false
        this.userToken = ""
    }

    async DelNotifications(){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.post(`${host}notifications/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.notifications = [{
                title: "",
                date: "",
                action: ""
            }]
            Hthis.notifications.slice()
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async LoadNotifications(){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.get(`${host}notifications/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.notifications = [{
                title: "",
                date: "",
                action: ""
            }]
            console.log(res.data)
            Hthis.notifications.slice()
            Hthis.notifications = res.data.notifications
            Hthis.chatCount = res.data.chat
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async UpdatePassword(password: string, sPassword: string){
        let data = {
            "error": false,
            "msg": ""
        }

        const token = this.userToken

        if(password != sPassword){
            return {
                "error": true,
                "msg": "Пароли должны совпадать"
            }
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password)){
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
            }
        }

        await axios.post(`${host}update-password/`, {
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = res.data
        }).catch(error => {
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async login(email: string, pass: string){
        let data = {
            "error": false,
            "msg": ""
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(pass)){
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
            }
        }

        await axios.post(`${host}login/`, {
            email: email,
            password: pass,
        }).then(res => {
            if(res.data.error == false){
                localStorage.setItem("userStorage", JSON.stringify({
                    pk: res.data.msg.pk,
                    isAuthenticated: true,
                    userToken: res.data.msg.userToken,
                    userName: res.data.msg.userName,
                    image: `https://api.blogerito.ru${res.data.msg.image != "/media/0" ? res.data.msg.image : "/media/0a7187c9b5aa4b3c9a84c39c7fd36fc0.webp"}`,
                    userType: res.data.msg.userType,
                }))
                data = {
                    "error": false,
                    "msg": "Вы успешно вошли"
                }
            }else{
                data = {
                    "error": true,
                    "msg": res.data.msg
                }
            }
        }).catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data
    }

    async password(email: string){
        let data = {
            "error": false,
            "msg": ""
        }

        await axios.post(`${host}password/`, {
            email: email,
        }).then(res => {
            data = res.data
        }).catch(function (error) {
            data = {
                "error": true,
                "msg": error
            }
        })

        return data
    }

    async setLegal(
        LegalFIO: string = "",
        LegalINN: string = "",
        LagalConsultation: boolean = false,
        pk: number
    ){

        await axios.post(`${host}registration-legal/`, {
            LegalFIO: LegalFIO,
            LegalINN: LegalINN == "" ? "-" : LegalINN,
            LagalConsultation: LagalConsultation,
            pk: pk
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            console.log(res.data)
            //data = res.data
        }).catch(function (error) {
            console.log(error)
        })
    }

    async register(
        name: string,
        email: string,
        phone: string,
        pass: string,
        cPass: string,
        userType: string,
        regLink: string,
        tglogin: string
    ){
        let data = {
            "error": false,
            "msg": "",
            "pk": 0
        }

        if(pass != cPass){
            return {
                "error": true,
                "msg": "Пароли должны совпадать",
                "pk": 0
            }
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(pass)){
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре",
                "pk": 0
            }
        }

        await axios.post(`${host}registration/`, {
            name: name,
            email: email,
            phone: phone,
            password: pass,
            userType: userType,
            regLink: regLink == "" ? "-" : regLink,
            tglogin: tglogin == "" ? "-" : tglogin,
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            data = res.data
        }).catch(function (error) {
            console.log(error)
            data = {
                "error": true,
                "msg": "error",
                "pk": 0
            }
        })

        return data
    }

    async AccountInfo(){
        let data = {
            "error": false,
            "msg": ""
        }
        const token = this.userToken
        const Hthis = this
        await axios.get(`${host}account-applications/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.applicationsActive = []
            Hthis.applicationsStory = []

            if(Hthis.userType == "seller"){
                res.data.story.forEach((item: Integrations, index: number) => {
                    Hthis.applicationsActive.push(new Application({
                        pk: 0,
                        integration: new Integrations({...item}),
                        status: "",
                        date: ""
                    }))
                })
                console.log(Hthis.applicationsActive)
            }else{
                res.data.story.forEach((item: Application, index: number) => {
                    Hthis.applicationsStory.push(new Application({...item}))
                })
            }

            res.data.active.forEach((item: Application, index: number) => {
                Hthis.applicationsActive.push(new Application({...item}))
            })
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }

    async SaveUserpic(userpic: any){
        let data = {
            "error": false,
            "msg": ""
        }

        const token = this.userToken
        const Hthis = this

        const form = new FormData();
        form.append("image", userpic)

        await axios.post(`${host}updateuserpic/`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": res.data.msg
            }

            Hthis.image = `https://api.blogerito.ru${res.data.msg}`

            localStorage.setItem("userStorage", JSON.stringify({
                isAuthenticated: true,
                userToken: Hthis.userToken,
                userName: Hthis.userName,
                image: Hthis.image,
                userType: Hthis.userType,
            }))

        }).catch(error=>{
            data = {
                "error": true,
                "msg": "Error"
            }
        })

        return data
    }

    async UnlinkTelegram(){
        let data = {
            "error": false,
            "msg": ""
        }

        const token = this.userToken
        const Hthis = this

        await axios.post(`${host}notification-unlink/`, {}, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.typeObject!.chatID = ""
            data = {
                "error": false,
                "msg": "Аккаунт отвязан"
            }
        }).catch(function(error){
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }
}

export class DashbordItem{
    pk: number
    title: string
    image: string
    desc: string

    constructor(pk: number ,title: string, image: string, desc: string = ""){
        this.pk = pk
        this.title = title
        this.image = image
        this.desc = desc
    }



}

export class Dashbord{
    list: DashbordItem[] = []

    async getData(){
        const Hthis = this
        await axios.get(`${host}dashbord/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: {pk: number, title: string, image: string, desc: string}) => {
                this.list.push(new DashbordItem(item.pk, item.title, item.image, item.desc))
            })
            console.log(res.data.content)
        }).catch(function (error) {
            console.log("error")
        })
    }
}

export class SellerItem{
    pk: string
    reviews: ReviewsInfo[]
    targetPage = 1
    countPage = 1
    sellerInfo?: SellerDopInfo

    constructor(pk: string){
        this.pk = pk
        this.reviews = []
        this.reviews.shift()
    }

    async Load(token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}seller/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            res.data.phone = ""
            Hthis.sellerInfo = res.data
            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(function (error) {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })
        return data
    }

    async LoadReviews(token: string, reviewsType: string, userType: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        console.log(this.sellerInfo?.pk)
        await axios.get(`${host}reviews/`, {
            params: {
                page: this.targetPage,
                user: this.sellerInfo?.pk ? this.sellerInfo.pk : 0,
                reviewsType: reviewsType,
                userType: userType
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            res.data.content.forEach((item: ReviewsInfo)=>{
                this.reviews.push({...item})
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 1)

            console.log(Hthis.reviews)
            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(error => {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class BlogerCatalogItem{
    blogerInfo?: UserDopInfo
    pk: string
    reviews: ReviewsInfo[]
    targetPage = 1
    countPage = 1

    constructor(pk: string){
        this.pk = pk
        this.reviews = []
        this.reviews.shift()
    }

    async Load(token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}bloger/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)


            Hthis.blogerInfo = res.data

            const date = new Date(Hthis.blogerInfo!.dateActivity)
            const now = new Date()
            Hthis.blogerInfo!.dateActivity = (now.getTime() - date.getTime())/(24*3600*1000) < 30 ? "Активный блогер" : ""

            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(function (error) {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })
        return data
    }

    async LoadReviews(token: string, reviewsType: string, userType: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}reviews/`, {
            params: {
                page: this.targetPage,
                user: this.blogerInfo?.pk ? this.blogerInfo.pk : 0,
                reviewsType: reviewsType,
                userType: userType
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            res.data.content.forEach((item: ReviewsInfo)=>{
                this.reviews.push({...item})
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 1)

            console.log(Hthis.reviews)
            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(error => {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class BlogerCatalog{
    blogerList: UserDopInfo[]
    targetPage = 1
    countPage = 1
    constructor(){
        this.blogerList = []
    }

    async LoadCatalog(token: string, search: string, sort: string, form: {
        postType1: boolean,
        postType2: boolean,
        postType3: boolean,
        postType4: boolean,
        postType5: boolean,
        youtube: boolean,
        vk: boolean,
        instagram: boolean,
        rutube: boolean,
        telegram: boolean,
        integration: boolean,

        haveIntegrationYes: boolean,
        haveIntegrationNo: boolean,
        blogerActiveYes: boolean,
        blogerActiveNo: boolean,
        barter: boolean,
        paid: boolean
    }){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}bloger/`, {
            params: {
                page: this.targetPage,
                search: search,
                sort: sort,
                postType1: form.postType1,
                postType2: form.postType2,
                postType3: form.postType3,
                postType4: form.postType4,
                postType5: form.postType5,
                youtube: form.youtube,
                vk: form.vk,
                instagram: form.instagram,
                rutube: form.rutube,
                telegram: form.telegram,
                integration: form.integration,

                haveIntegrationYes: form.haveIntegrationYes,
                haveIntegrationNo: form.haveIntegrationNo,
                blogerActiveYes: form.blogerActiveYes,
                blogerActiveNo: form.blogerActiveNo,
                barter: form.barter,
                paid: form.paid
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
            Hthis.blogerList = []

            const now = new Date()
            res.data.content.forEach((item:UserDopInfo) => {
                const date = new Date(item.dateActivity)
                item.dateActivity = (now.getTime() - date.getTime())/(24*3600*1000) < 30 ? "Активный блогер" : ""
                Hthis.blogerList.push(item)
            })

            Hthis.countPage = Math.ceil(res.data.pageCount / 20)
            data = {
                "error": false,
                "msg": "Данные загружены"
            }
        }).catch(function (error) {
            data = {
                "error": true,
                "msg": "Ошибка выполнения запроса"
            }
        })

        return data
    }
}

export class PayLog{
    payList: PayInfo[]
    countPage: number = 1
    targetPage: number = 1

    constructor(){
        this.payList = []
        this.payList.shift()
    }


    async LoadCatalog(token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this
        await axios.get(`${host}pay/`, {
            params: {
                'page': Hthis.targetPage
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.payList = []

            res.data.content.forEach((item: PayInfo, index: number) => {
                Hthis.payList.push({...item})
            })

            Hthis.countPage = Math.ceil(res.data.pageCount / 20)
        }).catch(error => {
            data = {
                "error": true,
                "msg": "error"
            }
        })
        return data
    }
}

export class MyTask{
    countPage: number = 1
    targetPage: number = 1
    list: {
        pk: number
        date: string
        statusDate: string
        status: string
        integration: {
            pk: number
            title: string
            miniDesc: string
            tz: string
            productImage: string
            seller: {
                pk: number
                name: string
            }
        }
    }[] = []

    constructor(){}

    async SetStatus(targetStatus: number, index: number, token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        await axios.post(`${host}my-tasks/`, {
            "id": Hthis.list[index].pk,
            "status": targetStatus
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list[index].status = res.data.status
        }).catch(error=>{
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        })
    }

    async LoadData(targetPage: number, token: string){
        let data = {
            "error": false,
            "msg": ""
        }
        const Hthis = this

        await axios.get(`${host}my-tasks/`, {
            params: {
                'page': Hthis.targetPage,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: any, index: number) => {
                Hthis.list.push(item)
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 20)
        }).catch(error=>{
            data = {
                "error": true,
                "msg": "error"
            }
        })

        return data

    }
}

export class TemplatesMsgItem{
    id: number
    title: string
    minidesc: string

    constructor(title: string, minidesc: string, id: number){
        this.id = id
        this.title = title
        this.minidesc = minidesc
    }

    async delData(token: string){
        await axios.delete(`${host}templates-msg/${this.id}/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log("true")
        })
        return true
    }
}
export class TemplatesMsg{
    list: TemplatesMsgItem[] = []
    constructor(){}

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}templates-msg/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: TemplatesMsgItem, index: number) => {
                Hthis.list.push(new TemplatesMsgItem(item.title, item.minidesc, item.id))
            })
        }).catch(function(error){
            console.log("error")
        })
        return true
    }

    async addData(title: string, minidesc: string, token: string){
        await axios.post(`${host}templates-msg/`, {
            title: title,
            minidesc: minidesc
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log("true")
        }).catch(error=>{
            console.log(error)
        })
        return true
    }
}
