import React, { ChangeEvent, useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext" 
import { BlogerCatalog } from "../classes"
import {CatalogItem } from "../component/CatalogItem"
import axios from "axios"
import { Loader } from "../component/Loader"
import { Navigation } from "../component/Navigation"

export const BlogerCatalogPage = () => {  
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true)    
    const [showPopup, setShowPopup] = useState(false)  
    const [search, setSearch] = useState("")
    const [sort, setSort] = useState("-integration")
    const [catalog, setCatalog] = useState(new BlogerCatalog())

    const [popupType, setPopupType] = useState("") 
    const [popupError, setPopupError] = useState(true) 


    const [form, setForm] = useState({ 
        postType1: false,
        postType2: false,
        postType3: false,
        postType4: false, 
        postType5: false,

        youtube: false,
        vk: false,
        instagram: false,
        rutube: false,
        telegram: false,
        integration: false,

        haveIntegrationYes: false,
        haveIntegrationNo: false,

        blogerActiveYes: false,
        blogerActiveNo: false,

        barter: false,
        paid: false,
    })
    
    const filterSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        catalog.LoadCatalog(auth.user.userToken, search, sort, form).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.checked })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        catalog.LoadCatalog(auth.user.userToken, search, sort, form).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }


    const [integrationList, setIntegrationList] = useState([])  
     

    useEffect(()=>{
        catalog.LoadCatalog(auth.user.userToken, search, sort, form).then(async function(response){
            await axios.get(`https://api.blogerito.ru/form-integration/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => { 
                setIntegrationList(res.data) 
                setLoad(false)
            }).catch(function(error){
                console.log(error)
                alert("error")
            })
        }).catch(function(error){
            alert("error")
        })
    },[])


    const clickIvent = (target: number) => {
        catalog.targetPage = target
        setLoad(true)
        catalog.LoadCatalog(auth.user.userToken, search, sort, form).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    if(load){
        return(
            <Loader /> 
        )
    }

    return( 
        <>
            <section className="catalog">
                <div className="content">
                    <form className="search-line" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Поиск" value={search} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearch(event.target.value)
                        }} />
                        <button>
                            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.9999" cy="12.0556" r="11.3056" stroke="white" stroke-width="1.5"/>
                                <path d="M5.90839 20.5654L0.944336 25.5294" stroke="white" stroke-width="1.5"/>
                            </svg>
                        </button>
                    </form>

                    <div className="row">
                        <div className="col-lg-3">
                            <h2 className="title">
                                Фильтры<span>:</span>
                            </h2>

                            <div className="catalog-filter">
                                <form onSubmit={filterSubmit}>
                                    <div className="catalog-filter__item">
                                        <h3>Тип задания</h3>
                                        <label>
                                            <input type="checkbox" name="postType1" checked={form.postType1 ? true:false} onChange={changeHandler} />
                                            Рекламный пост
                                        </label>
                                        <label>
                                            <input type="checkbox" name="postType2" checked={form.postType2 ? true:false} onChange={changeHandler} />
                                            Выкуп на маркетплейсе
                                        </label>
                                        <label>
                                            <input type="checkbox" name="postType3" checked={form.postType3 ? true:false} onChange={changeHandler} />
                                            Отзыв на маркетплейсе
                                        </label>
                                        <label>
                                            <input type="checkbox" name="postType5" checked={form.postType5 ? true:false} onChange={changeHandler} />
                                            Видеоотзыв на маркетплейсе
                                        </label>
                                        <label>
                                            <input type="checkbox" name="postType4" checked={form.postType4 ? true:false} onChange={changeHandler} />
                                            Раздача товаров подписчикам
                                        </label>
                                    </div>

                                    <div className="catalog-filter__item">
                                        <h3>Социальная сеть</h3>
                                        <label>
                                            <input type="checkbox" name="youtube" checked={form.youtube ? true:false} onChange={changeHandler} />
                                            Youtube
                                        </label>
                                        <label>
                                            <input type="checkbox" name="vk" checked={form.vk ? true:false} onChange={changeHandler} />
                                            VK
                                        </label>
                                        <label>
                                            <input type="checkbox" name="instagram" checked={form.instagram ? true:false} onChange={changeHandler} />
                                            Instagram
                                        </label>
                                        <label>
                                            <input type="checkbox" name="telegram" checked={form.telegram ? true:false} onChange={changeHandler} />
                                            Telegram
                                        </label>
                                        <label>
                                            <input type="checkbox" name="rutube" checked={form.rutube ? true:false} onChange={changeHandler} />
                                            Rutube
                                        </label> 
                                    </div>

                                    <div className="catalog-filter__item">
                                        <h3>Есть интеграции</h3>
                                        <label>
                                            <input type="checkbox" name="haveIntegrationYes" checked={form.haveIntegrationYes ? true:false} onChange={changeHandler} />
                                            Да
                                        </label>
                                        <label>
                                            <input type="checkbox" name="haveIntegrationNo" checked={form.haveIntegrationNo ? true:false} onChange={changeHandler} />
                                            Нет
                                        </label>
                                    </div>

                                    <div className="catalog-filter__item">
                                        <h3>Активные блогеры</h3>
                                        <label>
                                            <input type="checkbox" name="blogerActiveYes" checked={form.blogerActiveYes ? true:false} onChange={changeHandler} />
                                            Да
                                        </label>
                                        <label>
                                            <input type="checkbox" name="blogerActiveNo" checked={form.blogerActiveNo ? true:false} onChange={changeHandler} />
                                            Нет
                                        </label>
                                    </div>

                                    <div className="catalog-filter__item">
                                        <h3>Способы оплаты</h3>
                                        <label>
                                            <input type="checkbox" name="barter" checked={form.barter ? true:false} onChange={changeHandler} />
                                            Бартер
                                        </label>
                                        <label>
                                            <input type="checkbox" name="paid" checked={form.paid ? true:false} onChange={changeHandler} />
                                            Платно
                                        </label>
                                    </div>

                                    <button>Найти</button>
                                </form> 
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <h2 className="title">
                                Каталог блогеров<span>:</span>
                            </h2> 
                            <div style={{
                                marginBottom: 30,
                                backgroundColor: "red",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: 10,
                                marginTop: "-10px",
                                fontSize: "15px",
                                textAlign: "center",
                                textTransform: "uppercase"
                            }}>
                                Делайте упор на массовость, а не на количество подписчиков блогера.<br/>
                                Обращайте внимание на рейтинг, отзывы и данные о подтверждении аккаунта
                            </div>
                            <form>
                                <select onChange={(e)=>{ 
                                    setSort(e.target.value)
                                    //alert(e.target.value)
                                    console.log(e.target.value)
                                    setLoad(true)
                                    catalog.LoadCatalog(auth.user.userToken, search, e.target.value, form).then(function(response){
                                        setLoad(false)
                                    }).catch(function(error){
                                        alert("error")
                                    })
                                }}>
                                    <option value={"-integration"} selected={sort == "-integration"}>По рейтингу</option>
                                    <option value={"-subscribers"} selected={sort == "-subscribers"}>По виду блогера</option>
                                    <option value={"-integrationCost"} selected={sort == "-integrationCost"}>По убыванию цены</option>
                                    <option value={"integrationCost"} selected={sort == "integrationCost"}>По возрастанию цены</option>
                                </select>
                            </form>

                            {
                                catalog.blogerList ? (
                                    catalog.blogerList.length ? (
                                        catalog.blogerList.map((item, index)=>{
                                            return (
                                                <CatalogItem setShowPopup={setShowPopup} setPopupType={setPopupType} setPopupError={setPopupError} setLoad={setLoad} integrationList={integrationList} item={item} key={index} />
                                            )
                                        })
                                    ):(
                                        <div>
                                            <h3>Список пуст</h3>
                                        </div>
                                    )
                                ):(
                                    <div>
                                        <h3>Список пуст</h3>
                                    </div>
                                )
                            }

                            <Navigation 
                                countPage={catalog.countPage}
                                targetPage={catalog.targetPage}
                                clickIvent={clickIvent}
                            /> 
                        </div>
                    </div> 
                </div>
            </section>

            { 
                popupType != "" ? (                                 
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            <div className="popup-msg__link" onClick={()=>{ 
                                setPopupType("") 
                            }}>
                                Продолжить
                            </div>
                        </div>
                    </div>
                ):""
            }   
        </>
    )
}