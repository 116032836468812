export const Navigation = (props: any) => {
    return(
        <>
            {
                props.countPage > 1 ? (
                    <div className="pay-page">
                        {
                            props.countPage != 0 ? (
                                <div>
                                    { 
                                        props.targetPage> 3 ? (
                                            <>
                                                <div onClick={()=>{
                                                    props.clickIvent(1)
                                                }}>
                                                    1
                                                </div>
                                                <div>
                                                    ...
                                                </div>
                                            </>
                                        ) : ""
                                    } 
                                    { 
                                        props.targetPage-2 > 0 ? (
                                            <div onClick={()=>{
                                                props.clickIvent(props.targetPage-2)
                                            }}>
                                                {props.targetPage-2}
                                            </div>
                                        ) : ""
                                    } 
                                    { 
                                        props.targetPage-1 > 0 ? (
                                            <div onClick={()=>{
                                                props.clickIvent(props.targetPage-1)
                                            }}>
                                                {props.targetPage-1}
                                            </div>
                                        ) : "" 
                                    }
                                    <div className="active">
                                        {props.targetPage}
                                    </div>
                                    { 
                                        props.targetPage+1 <= props.countPage ? (
                                            <div onClick={()=>{
                                                props.clickIvent(props.targetPage+1)
                                            }}>
                                                {props.targetPage+1}
                                            </div>
                                        ) : ""
                                    } 
                                    { 
                                        props.targetPage+2 <= props.countPage ? (
                                            <div onClick={()=>{
                                                props.clickIvent(props.targetPage+2)
                                            }}>
                                                {props.targetPage+2}
                                            </div>
                                        ) : ""
                                    } 
                                    { 
                                        props.targetPage+3 <= props.countPage ? (
                                            <>
                                                <div>
                                                    ...
                                                </div>
                                                <div onClick={()=>{
                                                    props.clickIvent(props.countPage)
                                                }}>
                                                    {props.countPage}
                                                </div>
                                            </>
                                        ) : ""
                                    } 
                                </div>
                            ) : ""
                        }
                    </div>
                ):""
            }
        </>
    )
}