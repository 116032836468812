export const statuses = {
    w8_bloger: 'Ожидает подтверждения от блогера',
    w8_seller: 'Ожидает подтверждения от рекламодателя',
    cancelled_bloger: 'Отменено блогером',
    cancelled_seller: 'Отменено рекламодателем',

    // in_process: 'Заявка принята',
    // tz_conf:'ТЗ принято',
    // w8_paydata: "Ожидание реквизитов",
    // ("payment_w8", "Ожидание оплаты"), #4. Ожидание оплаты
    // ("payment_completed","Блогер подтвердил оплату"), #5. Оплата получена
    // ("product_ordered","Товар заказан"), #6 Товар заказан
    // ("product_received","Товар получен"), #7 Товар получен
    // ("post_posted","Пост размещен"), #8 Пост размещен
    // ("bloger_done", 'Блогер подтвердил выполнение'), #9 Успешно завершено
    // ("w8_bloger_feedback", 'Ожидание отзыва от блогера'),
    //
    // ("done", 'Выполнено'),
}
